export const leftMenuBase = [
    {
        text: "Aerien",
        action: "setScene",
        target: "F10",
        icon: "plane"
    },
    {
        text: "R+8",
        action: "setScene",
        target: "F09"
    },
    {
        text: "R+7",
        action: "setScene",
        target: "F08"
    },

    {
        text: "R+6",
        action: "setScene",
        target: "F07"
    },

    {
        text: "R+5",
        action: "setScene",
        target: "F06"
    },

    {
        text: "R+4",
        action: "setScene",
        target: "F05"
    },
    {
        text: "R+3",
        action: "setScene",
        target: "F04"
    },
    {
        text: "R+2",
        action: "setScene",
        target: "F03"
    },
    {
        text: "R+1",
        action: "setScene",
        target: "F02"
    },
    {
        text: "RDC",
        action: "setScene",
        target: "F01"
    },]

export const bot_menu_plan_plan = [
    {
        text: "Plan",
        action: "setLayout",
        target: "poi_pp_plan"
    },
    {
        text: "Plan R+1",
        action: "setLayout",
        target: "poi_pp_plan_2"
    },

]

export const leftMenuHouse = [
    {
        text: "Toit",
        action: "setOrbit",
        target: "%orbit%"
    },
    {
        text: "R+1",
        action: "setOrbit",
        target: "%orbit%_Floor2"
    },
    {
        text: "RDC",
        action: "setOrbit",
        target: "%orbit%_Floor1"
    },
]

export const bot_menu_plan = [
    {
        text: "Plan",
        action: "setLayout",
        target: "poi_p_plan"
    },
]

export const bot_menu_plan_orbit_axo = [
    {
        text: "Plan",
        action: "setLayout",
        target: "p_poa_plan"
    },
    {
        text: "Orbital",
        action: "setLayout",
        target: "p_poa_orbit"
    },
    {
        text: "Plan 3D",
        action: "setLayout",
        target: "p_poa_axo"
    },

]

export const bot_menu_plan_orbit_axo_axo = [
    {
        text: "Plan",
        action: "setLayout",
        target: "p_poaa_plan"
    },
    {
        text: "Orbital",
        action: "setLayout",
        target: "p_poaa_orbit"
    },
    {
        text: "Plan 3D",
        action: "setLayout",
        target: "p_poaa_axo"
    },
    {
        text: "Plan 3D R+1",
        action: "setLayout",
        target: "p_poaa_axo_2"
    },
]

export const bot_menu_plan_orbit = [
    {
        text: "Plan",
        action: "setLayout",
        target: "p_po_plan"
    },
    {
        text: "Orbital",
        action: "setLayout",
        target: "p_po_orbit"
    },
]

export const bot_menu_plan_axo = [
    {
        text: "Plan",
        action: "setLayout",
        target: "poi_pa_plan"
    },
    {
        text: "Plan 3D",
        action: "setLayout",
        target: "poi_pa_axo"
    },
]
export const bot_menu_plan_plan_axo_axo = [
    {
        text: "Plan",
        action: "setLayout",
        target: "poi_ppaa_plan"
    },
    {
        text: "Plan R+1",
        action: "setLayout",
        target: "poi_ppaa_plan_2"
    },
    {
        text: "Plan 3D",
        action: "setLayout",
        target: "poi_ppaa_axo_2"
    },
    {
        text: "Plan 3D R+1",
        action: "setLayout",
        target: "poi_ppaa_axo_2"
    },
]


export const bot_menu_plan_axo_axo = [
    {
        text: "Plan",
        action: "setLayout",
        target: "poi_paa_plan"
    },
    {
        text: "Plan 3D",
        action: "setLayout",
        target: "poi_paa_axo"
    },
    {
        text: "Plan 3D R+1",
        action: "setLayout",
        target: "poi_paa_axo_2"
    },

]

export const bot_menu_all = [
    {
        text: "Plan",
        action: "setLayout",
        target: "poi_plan"
    },
    {
        text: "Plan 3D",
        action: "setLayout",
        target: "poi_axo"
    },
    {
        text: "Visite 360",
        action: "setLayout",
        target: "poi_virtual"
    },
    {
        text: "Orbital",
        action: "setLayout",
        target: "orbit_T5A"
    }

]
